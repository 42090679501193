<template>
  <v-card>
    <v-container class="py-0">
      <v-row>
        <v-col cols="11" class="py-0 pl-5">
          <v-card-title class="pl-1 pt-2 p-0 primary--text"
            >Log de eliminaciones - {{ modulo }}
          </v-card-title>
        </v-col>
        <v-col cols="1" class="py-0 pr-2">
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="to-right"
                icon
                @click="closeModalLogEliminaciones()"
              >
                <v-icon v-bind="attrs" v-on="on">{{ closeIcon }}</v-icon>
              </v-btn>
            </template>
            <span>Cerrar</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="py-0">
          <v-data-table
            dense
            :headers="headers"
            :items="registros"
            :search="search"
            id="data-table-log-eliminaciones"
            item-key="logEliminacionesId"
            show-expand
            :loading="isLoading"
            :expanded.sync="expanded"
          >
            <template v-slot:top>
              <v-container>
                <v-row>
                  <v-col cols="12" md="12" class="py-1 pb-2">
                    <v-text-field
                      v-model="search"
                      :append-icon="searchIcon"
                      label="Buscar"
                      dense
                      id="search-detalle-log-eliminaciones"
                      single-line
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <v-row class="fondoExpandable">
                  <v-col cols="12" class="py-0 pl-2">
                    <strong class="expandableInfoTitle">Descripción: </strong>
                    <span class="expandableInfoText">{{
                      item.descripcion
                    }}</span>
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>
<script>
import { mapActions } from "vuex";
import enums from "@/utils/enums/index";

export default {
  name: "LogEliminaciones",
  components: {},
  props: {
    modulo: {
      type: String,
      required: false,
      default: ""
    }
  },
  data: () => ({
    isLoading: false,
    modalVerTraductor: false,
    registros: [],
    searchIcon: enums.icons.SEARCH,
    closeIcon: enums.icons.CLOSE,
    chevronUpIcon: enums.icons.CHEVRON_UP,
    chevronDownIcon: enums.icons.CHEVRON_DOWN,
    seeIcon: enums.icons.SEE,
    search: "",
    loadingExportarExcel: false,
    expanded: [],
    headers: [
      {
        text: "Usuario",
        value: "usuario",
        sortable: false,
        align: "start"
      },
      {
        text: "Fecha eliminación ",
        sortable: false,

        value: "fecha",
        align: "start"
      }
    ]
  }),
  created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.getLogEliminaciones();
  },

  methods: {
    ...mapActions({
      setAlert: "user/setAlert",
      getLogEliminacionesByModulo: "shared/getLogEliminacionesByModulo"
    }),
    handleExpansion(item, state) {
      const itemIndex = this.expanded.indexOf(item);
      state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
    },

    async getLogEliminaciones() {
      this.isLoading = true;
      const data = await this.getLogEliminacionesByModulo(this.modulo);
      this.registros = data;
      this.isLoading = false;
      if (this.registros.length < 1) {
        this.setAlert({
          type: "warning",
          message: "No hay actualizaciones para mostrar"
        });
      }
    },
    closeModalLogEliminaciones() {
      this.$emit("closeModalLogEliminaciones");
    }
  }
};
</script>

<style scoped>
.with-divider {
  border-left: 1px solid #939393;
  padding-left: 10px;
}
.expandableInfoTitle {
  font-size: 0.8rem;
  padding-left: 1rem;
}
.expandableInfoText {
  font-size: 0.7rem;
}
.fondoExpandable {
  background-color: #0000000c;
}
</style>
